@import 'react-big-calendar/lib/sass/styles';

.rbc-calendar {
  padding-top: 1rem;
  height: 80vh;
  width: 85vw;
  max-width: 1500px;
  font-family: 'Roboto Condensed', sans-serif;
  
  .rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
    display: inherit;
    background-color: #fff;
  }

  .rbc-time-content {
    display: none;
  }

  .rbc-toolbar {
    gap: 0.5rem;

    button {
      color: #fff;
      text-transform: uppercase;

      &.rbc-active {
        color: #fff;
      }
    }
  }

  .rbc-toolbar-label, .rbc-header {
    font-family: 'Raleway', sans-serif;
    font-size: 1.1rem;
  }
  
  .rbc-event {
    font-family: 'Raleway', sans-serif;
  }
}

.theme-hunt {
  .rbc-toolbar {
    button {
      background-color: #323C1A;

      &.rbc-active {
        background-color: #C57200;
      }
    }
  }
  
  .rbc-event {  
    &.trip {
      background-color: #323C1A;
    }

    &.otherTrip {
      background-color: #C57200;
    }

    &.userEvent {
      background-color: #998f76;
    }
  }
}

.theme-fish {
  .rbc-toolbar {
    button {
      background-color: #1D3257;

      &.rbc-active {
        background-color: #618D48;
      }
    }
  }

  .rbc-event {
    &.trip {
      background-color: #1D3257;
    }

    &.otherTrip {
      background-color: #618D48;
    }

    &.userEvent {
      background-color: #5589AC;
    }
  }
}