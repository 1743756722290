body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button a {
  text-decoration: none;
  color: inherit;
}

input:-webkit-autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0 50px transparent inset;
  -webkit-text-fill-color: black;
}